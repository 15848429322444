<template>
  <div class="wrap">
    <NavBar
      :fixed="true"
      :title="$t('mine.serviceTools.address')"
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <CellGroup class="content_wrap">
      <Field
        v-model="addressInfo.name"
        label-width="fit-content"
        required
        colon
        :label="$t('address.name')"
        :placeholder="$t('address.inputPlaceholder')"
      />
      <Field
        v-model="addressInfo.phone"
        label-width="fit-content"
        required
        colon
        :label="$t('address.phone')"
        :placeholder="$t('address.inputPlaceholder')"
      />
      <Field
        v-model="addressInfo.address"
        rows="6"
        autosize
        type="textarea"
        maxlength="50"
        :placeholder="$t('address.areaPlaceholer')"
      />
    </CellGroup>
    <div class="save" @click="onSave">{{$t('address.submit')}}</div>
  </div>
</template>

<script>
import { ContactEdit, NavBar, Field, Cell, CellGroup, Toast } from "vant";
import { $get, $post } from "../../../utils/request";
export default {
  components: { ContactEdit, NavBar, Field, CellGroup },
  data() {
    return {
      addressInfo: {
        name: "",
        phone: "",
        address: "",
      },
      reqNum: 0,
    };
  },
  methods: {
    async onSave() {
      if (!!this.addressInfo.name && !!this.addressInfo.phone) {
        this.reqNum++;
        try {
          const res = await $post("/users/address/add", this.addressInfo);
          this.reqNum--;
          const { ret, msg, data } = res.data;
          this.$toast(msg);
          if (ret == 1) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 800);
            return;
          }
        } catch (error) {
          this.reqNum--;
        }
        return;
      }
      Toast(this.$t("Hint.notInput"));
    },
    async getAddress() {
      this.reqNum++;
      try {
        const res = await $get("/users/address");
        this.reqNum--;
        const { ret, msg, data } = res.data;
        if (ret === 1) {
          const info = data[0] || {};
          if (!!Object.keys(info).length) {
            this.addressInfo = data[0];
            return;
          }
          return;
        }
        Toast(msg);
      } catch (error) {
        this.reqNum--;
      }
    },
  },
  created() {
    this.getAddress();
  },
  watch: {
    reqNum: function (value) {
      if (!value) {
        Toast.clear();
        return;
      }
      Toast.loading();
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: #f5f5f5;
  .content_wrap {
    margin: calc(18rem / 16);
    border-radius: 8px;
    overflow: hidden;
    /deep/ .van-cell {
      &::after {
        height: 1px;
        background: #ccc;
      }
    }
  }
  .save {
    padding: calc(12rem / 16);
    background: var(--nav-background);
    margin: calc(36rem / 16) calc(18rem / 16);
    font-size: calc(16rem / 16);
    color: #fff;
    border-radius: 99px;
    text-align: center;
  }
}
</style>